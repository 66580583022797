// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-about-js": () => import("./../../../src/pages/About/About.js" /* webpackChunkName: "component---src-pages-about-about-js" */),
  "component---src-pages-big-dipper-big-dipper-js": () => import("./../../../src/pages/BigDipper/BigDipper.js" /* webpackChunkName: "component---src-pages-big-dipper-big-dipper-js" */),
  "component---src-pages-charge-knowlegde-charge-knowlegde-js": () => import("./../../../src/pages/ChargeKnowlegde/ChargeKnowlegde.js" /* webpackChunkName: "component---src-pages-charge-knowlegde-charge-knowlegde-js" */),
  "component---src-pages-dont-speak-dont-speak-js": () => import("./../../../src/pages/DontSpeak/DontSpeak.js" /* webpackChunkName: "component---src-pages-dont-speak-dont-speak-js" */),
  "component---src-pages-footer-footer-js": () => import("./../../../src/pages/Footer/Footer.js" /* webpackChunkName: "component---src-pages-footer-footer-js" */),
  "component---src-pages-future-future-js": () => import("./../../../src/pages/Future/Future.js" /* webpackChunkName: "component---src-pages-future-future-js" */),
  "component---src-pages-home-charger-home-charger-js": () => import("./../../../src/pages/HomeCharger/HomeCharger.js" /* webpackChunkName: "component---src-pages-home-charger-home-charger-js" */),
  "component---src-pages-home-home-js": () => import("./../../../src/pages/Home/Home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-app-install-app-js": () => import("./../../../src/pages/InstallApp/InstallApp.js" /* webpackChunkName: "component---src-pages-install-app-install-app-js" */),
  "component---src-pages-investment-relations-investment-relations-js": () => import("./../../../src/pages/InvestmentRelations/InvestmentRelations.js" /* webpackChunkName: "component---src-pages-investment-relations-investment-relations-js" */),
  "component---src-pages-pop-up-popup-js": () => import("./../../../src/pages/PopUp/Popup.js" /* webpackChunkName: "component---src-pages-pop-up-popup-js" */),
  "component---src-pages-smoke-out-smoke-out-js": () => import("./../../../src/pages/SmokeOut/SmokeOut.js" /* webpackChunkName: "component---src-pages-smoke-out-smoke-out-js" */),
  "component---src-pages-under-construction-js": () => import("./../../../src/pages/under-construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */)
}

